import React from 'react';
import Layout from '../component/Layout';

function SingleBlogPage() {
  return (
    <Layout>
    <div className="blog-post">
      <header className="blog-header">
        <h1>Blog Title</h1>
      </header>

      <article className="post">
        <h2>Post Title</h2>
        <div className="post-meta">
          <span>By Author Name</span>
          <span>Date Published</span>
          <span>Category</span>
        </div>
        <img src="featured-image.jpg" alt="Featured" />
        <p>Post content goes here...</p>
        {/* More content */}
      </article>

      <section className="author-bio">
        <img src="author-photo.jpg" alt="Author" />
        <div>
          <h3>About the Author</h3>
          <p>Author bio goes here...</p>
        </div>
      </section>

    </div>
    </Layout>
  );
}

export default SingleBlogPage;

import './App.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./pages/Home"
import KeyNote from './pages/KeyNote';
import Gallery from "./pages/Gallery";
import Blog from "./pages/Blog"
import Contact from './pages/Contact';
import SingleBlogPage from './pages/SingleBlogPage';
import BackToTop from './pages/BackTop';
import './font/fonts.css';

function App() {


  return (
    <Router>
      <div className="App">
        <Routes> 
          
        <Route path="" element={<Home />} />
          
        <Route path="/KeyNote" element={<KeyNote />} />
        <Route path="/Gallery" element={<Gallery />} /> 
        <Route path="/Blog" element={<Blog />} /> 
        <Route path="/Contact" element={<Contact />} /> 
        <Route path="/SingleBlogPage" element={<SingleBlogPage />} /> 
        {/* <Route path="/BackToTop" element={<BackToTop />} /> */}
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
  
}

export default App;

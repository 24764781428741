import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Blog.css';
import Dimg from '../images/quote4.jpg';


const Blog = () => {

  function toggleClamp(event) {
    const content = event.target.closest('.blog-content').querySelector('p');
    content.classList.toggle('expanded');

    if (content.classList.contains('expanded')) {
      event.target.textContent = 'Read Less';
    } else {
      event.target.textContent = 'Read More';
    }
  }

  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(true);
  const porturl = process.env.REACT_APP_PORT_URL;
  const baseurl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    axios.get(`${baseurl}/getAllblogads`)
      .then(response => {
        if (response.data) {
          setBlog(response.data);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  return (
    <Container className="blog-container mt-4 mb-4 bg-light bg-gradient rounded" id='blogSection'>
      <h1 className="cursive-heading2 my-1 mb-4">Motivation</h1>


      <Row>
        { !loading && blog.length > 0 ? (
          blog.map((item, index) => (
            <Col key={index} xs={12} sm={6} md={4} className="blog-col">
              <div style={{ overflow: 'hidden' }}>
               
                <img
                  src={`${porturl}${item.image}`}
                  alt={item.name}
                  className="img-fluid blog-image mb-3"
                  onError={(e) => { e.target.src = Dimg }} 
                />
              </div>

            </Col>
          ))
        ) : (
          <p>Loading blogs...</p>
        )}
      </Row>
    </Container>
  );
};

export default Blog;




import React, { useEffect, useState } from 'react';
import axios from 'axios';
import defImage from '../images/BooksAnu.png';  // Fallback image
import loader from '../images/loader.gif';     // Loader image

function ImageSlider() {
  const [slideImages, setSlideImages] = useState([]);
  const [loadingState, setLoadingState] = useState({});  
  const porturl = process.env.REACT_APP_PORT_URL;
  const baseurl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    // Fetch images from API
    axios.get(`${baseurl}/getAllPortfolios`)
      .then(response => {
        if (response.data) {
          setSlideImages(response.data); 
         
          const initialLoadingState = response.data.reduce((acc, _, index) => ({ ...acc, [index]: true }), {});
          setLoadingState(initialLoadingState);
        }
      })
      .catch(error => {
        console.error('Error fetching images:', error);
      });
  }, []);

  const handleImageLoad = (index) => {
    setLoadingState(prevState => ({ ...prevState, [index]: false })); 
  };

  return (
    <div className="carousel-box pt-1">
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval={4000}>
        <div className="carousel-indicators">
          {slideImages.map((item, index) => (
            <button
              key={item.id}
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              className={index === 0 ? 'active' : ''}
              aria-label={`Slide ${index + 1}`}
            />
          ))}
        </div>

        <div className="carousel-inner">
          {slideImages.map((item, index) => (
            <div key={item.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
              {loadingState[index] ? (
                <div> 
                  <img src={loader} alt="Loading..." /> 
                </div>
              ) : null}

              <img
                src={`${porturl}${item.image}`}
                className="d-block w-100"
                alt={`Slide ${index + 1}`}
                onLoad={() => handleImageLoad(index)}
                onError={(e) => { e.target.src = defImage }}
                style={{ display: loadingState[index] ? 'none' : 'block' }} 
                loading="lazy"
              />
            </div>
          ))}
        </div>

        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default ImageSlider;


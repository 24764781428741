import React, {useState, useEffect} from "react";
import { ArrowUpCircleFill } from 'react-bootstrap-icons';
import './BackTop.css';

const BackToTop = () => {

    const [showButton, setShowButton] = useState(false);

   useEffect(()=> {
    const handleScroll = ()=> {
        const secPage = window.innerHeight;
    if (window.scrollY > secPage) setShowButton(true)
        else setShowButton(false)
   };

   window.addEventListener("scroll", handleScroll);

},[]);


const scrollToTop = ()=>{

    window.scrollTo({top: 0, "behavior" : "smooth"})

}

return(
    <div>
        <button className={` backTopButton ${showButton ? 'visible': ''} `} onClick={scrollToTop} title="back to top">
        <ArrowUpCircleFill color="#fff" fontSize={40}/>
        </button>
    </div>
);

};

export default BackToTop;


import React, { useEffect, useState } from 'react';
import '../pages/KeyNote.css';
import { Container } from 'react-bootstrap';
import axios from 'axios';

import { ClipLoader } from 'react-spinners';

function KeyNote() {

  const [keynote, setKeynote] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const baseurl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    axios.get(`${baseurl}/getallmemberboard`)
      .then(response => {
        if (response.data) {
          setKeynote(response.data);
          setLoading(false); // Stop loading when data is fetched
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false); // Stop loading even if there's an error
      });
  }, []);

  return (
    <div className="my-journey-transparent-red" id='keynotesSection'>
      <Container className="my-journey-styled-container px-5">
       
        {loading ? (
          // Show a loader while data is being fetched
          <div className="loader-container">
            <ClipLoader color={"#ffffff"} size={50} /> 
   
            <p>Loading Keynote...</p>
          </div>
        ) : (
          // Render the content after the data has been fetched
          keynote.length > 0 && (
            <>
              <h1 className="cursive-heading2">{keynote[0]?.name}</h1>
              <p className="monospaced-text">
                <b>Dr. Anurag Soni</b> has been decorated with Bharat Vidya Ratna and Real Super Hero Award for his remarkable contributions in the academic field. He has also been honored in the British Parliament and Cambridge School of London. Apart from this, former Chief Minister of Madhya Pradesh, Shivraj Singh Chouhan has awarded ‘Captains of Industry Award’ to Dr. Soni. He also received Excellence in School Education Award in the British Parliament.
              </p>
              <p className="monospaced-text">
                {keynote[0]?.description}
              </p>
            </>
          )
        )}
      </Container>
    </div>
  );
}

export default KeyNote;

import React from 'react';
import './Home.css';  // Updated CSS file
import Layout from '../component/Layout';
import { Container } from 'react-bootstrap';
import Header from "../component/Header"
function MyJourney() {
  return (
    <Layout>
      <Header/>

    </Layout>
  );
}

export default MyJourney;

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios"; //
import "./contact.css";
import phone1 from "../images/contactImg.png";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [message, setMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const porturl = process.env.REACT_APP_PORT_URL;
  const baseurl = process.env.REACT_APP_BASE_URL;

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      name,
      email,
      contactNo,
      message,
    };

    axios
      .post(
        `${baseurl}/contact`,
        formData
      )
      .then((response) => {
        setResponseMessage("Message sent successfully!");
        console.log("Response:", response.data);
        // Clear the form fields
        setName("");
        setEmail("");
        setContactNo("");
        setMessage("");
      })
      .catch((error) => {
        setResponseMessage("Error sending message. Please try again.");
        console.error("Error:", error);
      });
  };

  const [setting, setSetting] = useState("");

  useEffect(() => {
    axios
      .get(`${baseurl}/settings`)
      .then((response) => {
        if (response.data) {
          setSetting(response.data);
        }
      })

      .catch((error) => {
        console.error("Error fetching footer settings :", error);
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
      });
  }, []);

  return (
    <Container>
      <Row className="contact-container mt-4 mb-4 flex" id="contactSection">
        <Col className="contactImage">
          <img src={phone1} alt="contact" />
        </Col>
        <Col>
          <h1 className="cursive-heading2">Contact Us</h1>
          <div className="contactPageHeading pt-4">
            <p>Any inquiries or questions feel free to ask.</p>
          </div>
          <Container className="w-75 mb-5">
            {/* Form Submission */}
            <form onSubmit={handleSubmit}>
              <Row className="input-row">
                <Col>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    className="input-field"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <Row className="input-row">
                <Col>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="input-field"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <Row className="input-row">
                <Col>
                  <input
                    type="text"
                    placeholder="Contact number"
                    className="input-field"
                    value={contactNo}
                    onChange={(e) => setContactNo(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col className="input-row">
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Write your message here"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <button type="submit" className="send-button">
                    Send Message
                  </button>
                </Col>
              </Row>
            </form>

            {responseMessage && (
              <p className="response-message">{responseMessage}</p>
            )}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import "./Gallery.css";
import img5 from "../images/ANURAG SONI/cali.jpg"; 
import loader from "../images/loader.gif";

const Gallery = () => {
  const [galleryImage, setGalleryImage] = useState([]);
  const [loadingImages, setLoadingImages] = useState(true); 

  const porturl = process.env.REACT_APP_PORT_URL;
  const baseurl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {

    axios.get(`${baseurl}/getAllservices`)
      .then((response) => {
        if (response.data) {
          setGalleryImage(response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching gallery images:', error);
      });
  }, []);

  const handleImageLoad = (index) => {
    setLoadingImages((prev) => ({ ...prev, [index]: false })); 
  };

  const handleImageError = (index, e) => {
    setLoadingImages((prev) => ({ ...prev, [index]: false })); 
    e.target.src = img5; 
  };

  const getValidImageUrl = (imagePath) => {
   
    const formattedPath = imagePath.split('\\').join('/'); 
    return `${porturl}${formattedPath}`;
  };

  return (
    <Container fluid className='gallery-container mt-4 mb-4 px-5' id='gallerySection'>
      <Row className='justify-content-center'>
        <h1 className='cursive-heading2 mb-1'>Gallery</h1>
        <h4 className='p-2 mb-3'>A Glimpse into his Journey</h4>
      </Row>

      <Row>
        {galleryImage.length > 0 ? (
          galleryImage.map((item, index) => (
            <Col key={index} lg={4} md={6} sm={12}>
              <div className='overflow-hidden'>
                {loadingImages[index] ? (
                  <img src={loader} alt="Loading..." className="img-fluid mb-4" />
                ) : (
                  <img 
                    src={getValidImageUrl(item.image)} 
                    alt={item.name || "Gallery Image"} 
                    className="img-fluid mb-4 border-rounded" 
                    onLoad={() => handleImageLoad(index)} 
                    onError={(e) => handleImageError(index, e)} 
                    style={{ display: loadingImages[index] ? 'none' : 'block' }} 
                  />
                )}
              </div>
            </Col>
          ))
        ) : (
          <div>No images available</div>
        )}
      </Row>
    </Container>
  );
};

export default Gallery;

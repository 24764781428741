import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Header.css';
import KeyNote from '../pages/KeyNote';
import Gallery from '../pages/Gallery';
import Blog from '../pages/Blog';
import Contact from '../pages/Contact';
import ImageSlider from '../pages/ImageSlider';
import axios from 'axios';

const Header = () => {
  const [journey, setJourney] = useState([]);
  const [loading, setLoading] = useState(true);

  const baseurl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    axios
      .get(`${baseurl}/getAllLeaderships`)
      .then((response) => {
        if (response.data) {
          setJourney(response.data);
          setLoading(false); 
        }
      })
      .catch((error) => {
        console.error('Error fetching journey data:', error);
        setLoading(false); 
      });
  }, []);

  return (
    <>
      <div className="header-wrapper">
        <div className="header-container">
          <div className="header-box text-center mx-auto w-100 px-5">
          
            {!loading && journey.length > 0 && (
              <>
                <h1 className="cursive-heading2 mb-4 text-capitalize">{journey[0].name}</h1>
                <p className="header-text text-white">{journey[0].description}</p>
              </>
            )}
            <p className="header-text text-white text-end" style={{ marginRight: '10%' }}> - Anuroop Roy</p>
          </div>
        </div>
      </div>

      <KeyNote />

      <div className="footer-container">
        <Container>
          <Row>
            <Col className="cardinal-box">
              <h1 className="cursive-heading2" style={{marginBottom: '0'}}>Collection of Books</h1>
              
            </Col>
          </Row>
        </Container>
      </div>

      <ImageSlider />
      <Gallery />
      <Blog />
      <Contact />
    </>
  );
};

export default Header;

// src/Footer.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import './Footer.css';

const Footer = () => {

  const [setting, setSetting] = useState('');

  useEffect(()=>{

    axios.get('https://crimsonssystems.com/anuragsoniportfolio/api/settings')
    .then((response) => {

      if(response.data){
        setSetting(response.data)
      }

    })

    .catch((error) => {
      console.error('Error fetching footer settings :', error);
    });

  }, []);

  

  return (
    // <footer
    <footer className="text-white text-center text-lg-start">
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
            
            <div><a className='brand-name p-3' style={{textDecoration: 'none'}} href='/'>{setting[0]?.website}</a></div>
            <Row>
               <Col>
                 <h3 className='quoteFooter'>
                 <span> Your </span> only limit <br/>  is your mind <br/> <span> Believe in yourself </span>
                 </h3>
               </Col>
            </Row>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase py-4">Quick Links</h5>
            <ul className="list-unstyled mb-0">
              <li>
                <a href="/" className="text-white">Home</a>
              </li>
              <li>
                <a href="#!" className="text-white">British Fort School</a>
              </li>
            
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase mb-0 py-4">Contact Us</h5>
            <ul className="list-unstyled">
              <li>
                 {setting[0]?.address}
              </li>

              <li>
                <a href="#!" className="text-white">Phone : {setting[0]?.contact}</a>
              </li>
              <li>
                <a href="#!" className="text-white">Email : {setting[0]?.email}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2024 Copyright | designed &amp; developed by -
        <a className="text-white footer-link" href="https://crimsonssystems.com/" target='_blank'> Crimsons Systems Jabalpur</a> | All rights reserved
      </div>
    </footer>
  );
}

export default Footer;
